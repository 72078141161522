const questions = [
  // 1 Слайд \\
  {
    questionText:
      "Oi, prazer em conhecê-lo! Sou Marcel e estou cursando a universidade.",
    questionDesc:
      "Hoje é um dia de folga, por isso quero ganhar o máximo possível. Qual é a primeira coisa a fazer?",
    answerOptions: [
      {
        answerText:
          "Vá até o centro, que está bem movimentado. Pode haver muitos pedidos lá.",
        answerResult:
          "Esperar por um pedido ou dormir é ótimo, sem dúvida nenhuma. No entanto, para aumentar seus ganhos, é melhor comprar um passe livre de 6, 12 ou 24 horas. Os motoristas faturam com taxa 0% até o término do período de passe livre adquirido.",
        result: true,
      },
      {
        answerText: "Compre um passe livre de algumas horas.",
        answerResult:
          "Esperar por um pedido ou dormir é ótimo, sem dúvida nenhuma. No entanto, para aumentar seus ganhos, é melhor comprar um passe livre de 6, 12 ou 24 horas. Os motoristas faturam com taxa 0% até o término do período de passe livre adquirido.",
        result: false,
      },
      {
        answerText: "Durma quando não souber o que fazer.",
        answerResult:
          "Esperar por um pedido ou dormir é ótimo, sem dúvida nenhuma. No entanto, para aumentar seus ganhos, é melhor comprar um passe livre de 6, 12 ou 24 horas. Os motoristas faturam com taxa 0% até o término do período de passe livre adquirido.",
        result: false,
      },
    ],
    image: "images/1.webp",
  },

  // 2 слайд \\
  {
    questionText:
      "Obrigado pela dica! Comprei um passe livre  de 12 horas. Qual é o próximo passo?",
    answerOptions: [
      {
        answerText:
          "Tomar um cafezinho! É preciso se animar um pouco antes do trabalho.",
        answerResult:
          "Comprou um passe livre, então, a taxa 0% foi ativada imediatamente. Aconselhamos não demorar e começar a atender os pedidos o mais rápido possível.",
        result: false,
      },
      {
        answerText: "Direto aos pedidos, o tempo está passando.",
        answerResult:
          "Comprou um passe livre, então, a taxa 0% foi ativada imediatamente. Aconselhamos não demorar e começar a atender os pedidos o mais rápido possível.",
        result: false,
      },
      {
        answerText:
          "Há bastante tempo, tanto para seus próprios negócios quanto para os pedidos.",
        answerResult:
          "Comprou um passe livre, então, a taxa 0% foi ativada imediatamente. Aconselhamos não demorar e começar a atender os pedidos o mais rápido possível.",
        result: false,
      },
    ],
    image: "images/2.webp",
  },

  // 3 слайд \\
  {
    questionText:
      "Em 12 horas, é possível concluir cerca de 18 pedidos e ganhar até R$ 410,00. Parece que comprar um passe livre é uma opção bem vantajosa. Hora de partir!",
    questionDesc:
      "Há um carro simples e uma motocicleta na garagem. Pode me ajudar a escolher o veículo com o qual posso ganhar mais dinheiro dirigindo?",
    answerOptions: [
      {
        answerText: "Motocicleta. É rápida nas ruas.",
        answerResult:
          "É possível ganhar bastante dinheiro com qualquer veículo. Temos uma boa dica de como aumentar sua renda 👉 Coloque a publicidade da Maxim no seu carro ou atenda aos pedidos em sua moto usando nosso uniforme e, então, o passe livre será mais barato e os ganhos serão maiores.",
        result: true,
      },
      {
        answerText: "Carro. É um clássico do transporte de passageiros.",
        answerResult:
          "É possível ganhar bastante dinheiro com qualquer veículo. Temos uma boa dica de como aumentar sua renda 👉 Coloque a publicidade da Maxim no seu carro ou atenda aos pedidos em sua moto usando nosso uniforme e, então, o passe livre será mais barato e os ganhos serão maiores.",
        result: false,
      },
      {
        answerText: "Você ganhará a mesma quantia em qualquer um dos veículos.",
        answerResult:
          "É possível ganhar bastante dinheiro com qualquer veículo. Temos uma boa dica de como aumentar sua renda 👉 Coloque a publicidade da Maxim no seu carro ou atenda aos pedidos em sua moto usando nosso uniforme e, então, o passe livre será mais barato e os ganhos serão maiores.",
        result: false,
      },
    ],
    image: "images/3.webp",
  },

  // 4 слайд \\
  {
    questionText:
      "Acabei de lembrar que meu pai precisa do carro. Ele vai trabalhar em outra cidade  hoje. Vou atender a pedidos com a moto.",
    questionDesc:
      "Só estou preocupado: como o passageiro perceberá que sou da Maxim?",
    answerOptions: [
      {
        answerText: "Grite bem alto «Maxim!» e acene com a mão.",
        answerResult:
          "Os passageiros confiam em motoristas com uniformes. Além disso, ainda podem deixar uma gorjeta pela conversa educada ou condução confortável.",
        result: false,
      },
      {
        answerText: "Não faça nada, o passageiro te localizará pelo mapa.",
        answerResult:
          "Os passageiros confiam em motoristas com uniformes. Além disso, ainda podem deixar uma gorjeta pela conversa educada ou condução confortável.",
        result: false,
      },
      {
        answerText:
          "Você pode atender a pedidos com a camisa de manga longa da marca.",
        answerResult:
          "Os passageiros confiam em motoristas com uniformes. Além disso, ainda podem deixar uma gorjeta pela conversa educada ou condução confortável.",
        result: true,
      },
    ],
    image: "images/4.webp",
  },

  // 5 слайд \\ Бандана [2]
  {
    questionText:
      "Aí vem meu primeiro pedido, tenho que começar a trabalhar! Alguma palavra de incentivo?",
    answerOptions: [
      {
        answerText: "Que a Força esteja com você!",
        answerResult:
          "A segurança dos passageiros é importante. Leve um capacete. Boas viagens!",
        result: false,
      },
      {
        answerText:
          "Mantenha a atenção! E não se esqueça do capacete para o passageiro.",
        answerResult:
          "A segurança dos passageiros é importante. Leve um capacete. Boas viagens!",
        result: true,
      },
      {
        answerText: "Boa jornada e pedidos vantajosos.",
        answerResult:
          "A segurança dos passageiros é importante. Leve um capacete. Boas viagens!",
        result: false,
      },
    ],
    image: "images/5.webp",
  },
];

export default questions;
