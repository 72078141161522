import React, { useState, useEffect } from "react";
import Title from "../Title/Title";
import Button from "../Button/Button";
import Desc from "../Desc/Desc";
import Paragraph from "../Paragraph/Paragraph";
import styles from "./Result.module.scss";
import classNames from "classnames";
import { SITE_URL } from "../../state/consts";
import { IconButton, Snackbar } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";

import { FacebookShareButton, WhatsappShareButton } from "react-share";

import { FacebookIcon, WhatsappIcon } from "react-share";

function Result({
  score,
  title,
  text,
  imageSrc,
  posterContainer,
  optionsContainer,
}) {
  const [offset, setOffset] = useState(false);
  const [showConditions, setShowConditions] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ImageURL = SITE_URL + imageSrc;

  // кнопка скопировать
  const [open, setOpen] = useState(false);

  const testClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  return (
    <>
      {!showConditions ? (
        <>
          <div className={posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={classNames(optionsContainer, styles.before)}>
            <div
              className={classNames({
                [styles.resultPosterContainer]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div className={styles.contentWrapper}>
                <div className={styles.score}>{score}</div>
                <Title value={title} />
                {text}
                <Desc
                  value={
                    "Você pode adquirir em: Menu → Dinheiro → Comprar um passe livre."
                  }
                />
              </div>

              <div className={styles.buttonBox}>
                <a
                  href={"https://taxseedriver.onelink.me/cZaK/quiz"}
                  target="_blank"
                  download="wallpaper"
                  rel="noreferrer"
                >
                  <Button
                    value="Comprar"
                    id="clickMarket"
                    className={styles.buttonPresent}
                  />
                </a>
              </div>
              <div
                className={classNames(
                  styles.contentWrapper,
                  styles.socialWrapper
                )}
              >
                <Paragraph value="Compartilhar" />
                <div className={styles.social}>
                  <FacebookShareButton
                    url={SITE_URL}
                    title="Ajude o herói de nosso quiz a obter a sua primeira renda ao volante. No final do jogo, você aprenderá como ganhar o máximo com a Maxim."
                    image={ImageURL}
                    hashtag="#Maxim"
                    id="facebook"
                  >
                    <FacebookIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.facebook)}
                    />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={SITE_URL}
                    title="Ajude o herói de nosso quiz a obter a sua primeira renda ao volante. No final do jogo, você aprenderá como ganhar o máximo com a Maxim."
                    image={ImageURL}
                    id="whatsapp"
                  >
                    <WhatsappIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.whatsapp)}
                    />
                  </WhatsappShareButton>

                  <div id="sharelink">
                    <IconButton
                      onClick={testClick}
                      color="primary"
                      className={styles.share}
                    >
                      <ShareIcon />
                    </IconButton>
                    <Snackbar
                      message="Link copiado."
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={1000}
                      onClose={() => setOpen(false)}
                      open={open}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={styles.resultContainer} m2>
            <div
              className={classNames({
                [styles.resultContainerBox]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div>
                <Title value="Syarat dan Ketentuan Umum Shopee Quiz" />
                <Paragraph value="Syarat dan ketentuan berikut ini («S&K» / «Syarat dan Ketentuan») berlaku untuk kontes kuis online («Kuis») yang diselenggarakan oleh PT Teknologi Perdana Indonesia (Maxim Indonesia) («Penyelenggara») di Toko Online Merchant di Shopee." />
                <Paragraph value="Berikut ini adalah ketentuan yang berlaku untuk Kuis:" />
                <ul>
                  <li>
                    <Paragraph value="Setiap individu yang merupakan warga negara Indonesia dan pada tanggal partisipasi telah mencapai usia 18 tahun dapat berpartisipasi dalam kompetisi Kuis ini melalui toko online resmi dari Shopee yang dibuat oleh Penyelenggara («Toko Shopee»), kecuali karyawan, distributor, kontraktor, dan vendor Penyelenggara." />
                  </li>
                  <li>
                    <Paragraph value="Semua orang yang tertarik untuk berpartisipasi dalam Kuis ini («Peserta») harus mengikuti instruksi yang disebutkan dalam tautan atau di Toko Shopee untuk menyelesaikan Kuis." />
                  </li>
                  <li>
                    <Paragraph value="«Peserta yang Memenuhi Syarat» adalah semua individu yang mengikuti Kuis melalui Toko Shopee akan menjadi Peserta yang Memenuhi Syarat  setelah mereka memberikan informasi yang diminta oleh penyelenggara dalam S&K dan Toko Shopee, menjawab jawaban yang benar secara maksimal untuk pertanyaan- pertanyaan dalam Kuis dan mematuhi S&K." />
                  </li>
                  <li>
                    <Paragraph value="Semua Peserta diwajibkan untuk memberikan nama lengkap sesuai KTP, nama perusahaan, ID email yang valid, dan nomor ponsel yang terdaftar." />
                  </li>
                  <li>
                    <Paragraph value="Kuis yang dilaksanakan berdasarkan  instruksi yang diberikan di Toko Shopee, hanya untuk produk T-Shirt dan Bandana." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak untuk meminta bukti identitas yang sah dari pemenang untuk memberikan kode promosi yaitu MAXIMQUIZ, dengan hadiah potongan diskon 10% dari total pembelian T-Shirt dan Bandana." />
                  </li>
                  <li>
                    <Paragraph value="Hadiah tidak dapat ditukar, tidak dapat dikembalikan, tidak dapat dipindahtangankan, dan tidak dapat dialihkan. Hadiah juga tidak dapat ditukar dengan uang tunai. Pemenang merupakan orang yang dapat mengklaim hadiah kode promosi yang diberikan oleh Penyelenggara." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak untuk mengganti kode promosi dengan hadiah lain yang memiliki nilai yang sama, sebagaimana ditentukan oleh Penyelenggara atas kebijakannya sendiri tanpa perlu persetujuan dari pemenang terlebih dahulu." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak, kapan saja, untuk membatalkan entri (dan peserta yang mengirimkan atau menyebabkan entri tersebut dikirimkan) yang dicurigai telah dikirimkan: (a) menggunakan informasi yang salah, tidak benar, curang, atau menyesatkan, termasuk namun tidak terbatas pada detail pribadi dan informasi kontak; dan/atau (b) dengan menggunakan beberapa identitas, alamat email, atau akun; dan/atau (c) dengan cara apa pun yang bertentangan dengan Syarat dan Ketentuan ini." />
                  </li>
                  <li>
                    <Paragraph value="Peserta setuju bahwa tidak ada klarifikasi yang akan diberikan oleh Penyelenggara atas pertanyaan dan jawaban yang digunakan dalam Kuis." />
                  </li>
                  <li>
                    <Paragraph value="Jika terdapat perselisihan mengenai identitas Peserta yang Memenuhi Syarat, penyelenggara berhak, atas kebijakannya sendiri, untuk menentukan identitas Peserta yang Memenuhi Syarat tanpa perlu persetujuan dan pemberitahuan terlebih dahulu kepada Peserta." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak, kapan saja selama, atau setelah tanggal penutupan kompetisi, untuk memverifikasi keabsahan entri dan Peserta yang Memenuhi Syarat (termasuk identitas Peserta yang Memenuhi Syarat dan tempat tinggal) dan mendiskualifikasi Peserta yang Memenuhi Syarat yang mengirimkan entri yang tidak sesuai dengan Syarat dan Ketentuan ini atau yang merubah proses entri. Kegagalan Penyelenggara untuk menegakkan hak-haknya pada tahap apa pun bukan merupakan pengabaian atas hak-hak tersebut." />
                  </li>
                  <li>
                    <Paragraph value="Kewajiban pajak yang timbul bagi pemenang hadiah akan dibayarkan oleh pemenang sebelum mengklaim hadiah." />
                  </li>
                  <li>
                    <Paragraph value="Kuis ini dilaksanakan sampai dengan 30 November 2023." />
                  </li>
                </ul>
                <Paragraph value="Penjurian akan dilakukan oleh Penyelenggara, sesuai dengan instruksi dari Kuis ini yang diumumkan oleh Tim Penyelenggara di Toko Shopee Penyelenggara dan berdasarkan jawaban benar maksimum yang diposting atau dipilih." />
                <br />
                <Title value="Syarat dan Ketentuan Umum Tokopedia Quiz" />
                <Paragraph value="Syarat dan ketentuan berikut ini («S&K» / «Syarat dan Ketentuan») berlaku untuk kontes kuis online («Kuis») yang diselenggarakan oleh PT Teknologi Perdana Indonesia (Maxim Indonesia) («Penyelenggara») di Toko Merchant Online di Tokopedia." />
                <Paragraph value="Berikut ini adalah ketentuan yang berlaku untuk Kuis:" />
                <ul>
                  <li>
                    <Paragraph value="Setiap individu yang merupakan warga negara Indonesia dan pada tanggal partisipasi telah mencapai usia 18 tahun dapat berpartisipasi dalam kompetisi Kuis ini melalui toko online resmi dari Tokopedia yang dibuat oleh Penyelenggara («Tokopedia»), kecuali karyawan, distributor, kontraktor, dan vendor Penyelenggara." />
                  </li>
                  <li>
                    <Paragraph value="Semua orang yang tertarik untuk berpartisipasi dalam Kuis ini («Peserta») harus mengikuti instruksi yang disebutkan dalam tautan atau di Toko Shopee untuk menyelesaikan Kuis.Semua orang yang tertarik untuk berpartisipasi dalam Kuis ini («Peserta») harus mengikuti instruksi yang disebutkan dalam tautan atau di Toko Shopee untuk menyelesaikan Kuis." />
                  </li>
                  <li>
                    <Paragraph value="«Peserta yang Memenuhi Syarat» adalah semua individu yang mengikuti Kuis melalui Tokopedia akan menjadi Peserta yang Memenuhi Syarat  setelah mereka memberikan informasi yang diminta oleh penyelenggara dalam S&K dan Tokopedia, menjawab jawaban yang benar secara maksimal untuk pertanyaan- pertanyaan dalam Kuis dan mematuhi S&K." />
                  </li>
                  <li>
                    <Paragraph value="Semua Peserta diwajibkan untuk memberikan nama lengkap sesuai KTP, nama perusahaan, ID email yang valid, dan nomor ponsel yang terdaftar." />
                  </li>
                  <li>
                    <Paragraph value="Kuis yang dilaksanakan berdasarkan instruksi yang diberikan di Tokopedia, hanya untuk produk T-Shirt dan Bandana." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak untuk meminta bukti identitas yang sah dari pemenang untuk memberikan kode promosi yaitu MAXIQUIZ, dengan hadiah potongan diskon 10% dari total pembelian T-Shirt dan Bandana." />
                  </li>
                  <li>
                    <Paragraph value="Hadiah tidak dapat ditukar, tidak dapat dikembalikan, tidak dapat dipindahtangankan, dan tidak dapat dialihkan. Hadiah juga tidak dapat ditukar dengan uang tunai. Pemenang merupakan orang yang dapat mengklaim hadiah kode promosi yang diberikan oleh Penyelenggara." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak untuk mengganti kode promosi dengan hadiah lain yang memiliki nilai yang sama, sebagaimana ditentukan oleh Penyelenggara atas kebijakannya sendiri tanpa perlu persetujuan dari pemenang terlebih dahulu." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak, kapan saja, untuk membatalkan entri (dan peserta yang mengirimkan atau menyebabkan entri tersebut dikirimkan) yang dicurigai telah dikirimkan: (a) menggunakan informasi yang salah, tidak benar, curang, atau menyesatkan, termasuk namun tidak terbatas pada detail pribadi dan informasi kontak; dan/atau (b) dengan menggunakan beberapa identitas, alamat email, atau akun; dan/atau (c) dengan cara apa pun yang bertentangan dengan Syarat dan Ketentuan ini." />
                  </li>
                  <li>
                    <Paragraph value="Peserta setuju bahwa tidak ada klarifikasi yang akan diberikan oleh Penyelenggara atas pertanyaan dan jawaban yang digunakan dalam Kuis." />
                  </li>
                  <li>
                    <Paragraph value="Jika terdapat perselisihan mengenai identitas Peserta yang Memenuhi Syarat, penyelenggara berhak, atas kebijakannya sendiri, untuk menentukan identitas Peserta yang Memenuhi Syarat tanpa perlu persetujuan dan pemberitahuan terlebih dahulu kepada Peserta." />
                  </li>
                  <li>
                    <Paragraph value="Penyelenggara berhak, kapan saja selama, atau setelah tanggal penutupan kompetisi, untuk memverifikasi keabsahan entri dan Peserta yang Memenuhi Syarat (termasuk identitas Peserta yang Memenuhi Syarat dan tempat tinggal) dan mendiskualifikasi Peserta yang Memenuhi Syarat yang mengirimkan entri yang tidak sesuai dengan Syarat dan Ketentuan ini atau yang merubah proses entri. Kegagalan Penyelenggara untuk menegakkan hak-haknya pada tahap apa pun bukan merupakan pengabaian atas hak-hak tersebut." />
                  </li>
                  <li>
                    <Paragraph value="Kewajiban pajak yang timbul bagi pemenang hadiah akan dibayarkan oleh pemenang sebelum mengklaim hadiah." />
                  </li>
                  <li>
                    <Paragraph value="Kuis ini dilaksanakan sampai dengan 30 November 2023." />
                  </li>
                </ul>
                <Paragraph value="Penjurian akan dilakukan oleh Penyelenggara, sesuai dengan instruksi dari Kuis ini yang diumumkan oleh Tim Penyelenggara di Tokopedia Penyelenggara dan berdasarkan jawaban benar maksimum yang diposting atau dipilih." />
                <Button
                  className={styles.buttonConditions}
                  value="Kembali"
                  onClick={() => {
                    setShowConditions(false);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Result;
